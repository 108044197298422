<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_812_8326)">
        <path d="M4.84299 5.59758C4.84299 5.59758 5.97846 5.51328 5.97846 4.18141C5.97846 2.84967 5.04941 2.19995 3.87249 2.19995H1.70627H1.64264H0V9.64205H1.64264H1.70627H3.87249C3.87249 9.64205 6.23649 9.71683 6.23649 7.44552C6.23661 7.44552 6.3397 5.59758 4.84299 5.59758ZM1.70627 3.5228H3.59379H3.87261C3.87261 3.5228 4.39907 3.5228 4.39907 4.29703C4.39907 5.07125 4.08931 5.18348 3.73834 5.18348H1.70627V3.5228ZM3.77266 8.31957H1.70627V6.3306H3.87249C3.87249 6.3306 4.6571 6.32057 4.6571 7.3527C4.6571 8.21335 4.0833 8.31093 3.77266 8.31957Z" fill="white"/>
        <path d="M7.78857 2.63843H10.8592V3.55494H7.78857V2.63843Z" fill="white"/>
        <path d="M9.38952 4.09351C6.52762 4.09351 6.53013 6.95265 6.53013 6.95265C6.53013 6.95265 6.33397 9.79765 9.38965 9.79765C9.38965 9.79765 11.9363 9.9432 11.9363 7.81844H10.6266C10.6266 7.81844 10.6703 8.61896 9.43336 8.61896C9.43336 8.61896 8.12379 8.70652 8.12379 7.32379H11.9799C11.9799 7.32379 12.4017 4.09351 9.38952 4.09351ZM10.5537 6.33049H8.10901C8.10901 6.33049 8.26896 5.18338 9.41858 5.18338C10.5683 5.18325 10.5537 6.33049 10.5537 6.33049Z" fill="white"/>
    </g>
    <defs>
        <clipPath id="clip0_812_8326">
            <rect width="12" height="12" fill="white"/>
        </clipPath>
    </defs>
</svg>