<svg class="sub-submenu-arrow" width="6" height="6" viewBox="0 0 6 6" fill="none"
		 xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_2895_14961)">
		<path d="M1.35547 0.289394L1.35547 5.71059C1.35547 5.96712 1.66525 6.09781 1.84918 5.91388L4.55978 3.20328C4.67111 3.09196 4.67111 2.90802 4.55978 2.79665L1.84918 0.086051C1.66525 -0.0978336 1.35547 0.032856 1.35547 0.289394Z" fill="#69B518"/>
	</g>
	<defs>
		<clipPath id="clip0_2895_14961">
			<rect width="6.00005" height="6" fill="white" transform="matrix(0 -1 1 0 0 6)"/>
		</clipPath>
	</defs>
</svg>