<svg x="0px" y="0px" viewBox="0 0 1346.8 300.8" xml:space="preserve">
<style type="text/css">
	.st0{fill:#0051F3;}
  .st1{fill:#4BD8F9;}
  .st2{fill:#FFA300;}
  .st3{fill:#FF7D64;}
  .st4{fill:#FFFFFF;}
</style>
	<g>
	<polygon class="st0" points="152.6,19.6 13.9,230.4 291.4,230.4 	"/>
		<polygon class="st1" points="291.4,230.4 193.9,230.4 103.9,93.6 152.6,19.6 	"/>
		<polygon class="st2" points="152.6,167.6 111.3,230.4 152.6,293.1 193.9,230.4 	"/>
		<polygon class="st3" points="152.6,167.6 152.6,293.1 152.6,293.1 111.3,230.4 152.6,167.6 	"/>
</g>
	<g>
	<path class="st4" d="M361.2,150.5c0,32,22.2,52.5,56.5,52.5c7.8,0,14.8-0.8,21.5-2.2v-28.2h-36.5v-31h72.2V223
		c-15.8,9-34,13-58.8,13c-56.5,0-92.5-33.2-92.5-85.5s36-85.5,92.5-85.5c24.8,0,43,4,58.8,13v40.5h-1.8c-14.5-14-33-20.5-55.5-20.5
		C383.4,98,361.2,118.5,361.2,150.5z"/>
		<path class="st4" d="M580.7,118v32h-1.2c-5-3.5-11.2-5.5-18.5-5.5c-20,0-29,12.2-29,38.5v50.5h-32.8V116h27.2l1.5,18.2h0.5
		c6.5-13.8,18-20.8,35-20.8C570.9,113.5,577.2,115.3,580.7,118z"/>
		<path class="st4" d="M709.2,174.8c0,35.8-26,61.2-62.8,61.2c-37,0-63-25.5-63-61.2c0-36,26-61.2,63-61.2
		C683.2,113.5,709.2,138.8,709.2,174.8z M616.4,174.8c0,19,12.2,32.2,30,32.2c17.5,0,29.8-13.2,29.8-32.2s-12.2-32.2-29.8-32.2
		C628.7,142.5,616.4,155.8,616.4,174.8z"/>
		<path class="st4" d="M858.7,233.5h-31.2l-23.5-71h-0.5l-23.8,71h-31.2L708.9,116h34.8l22,75.8h0.5l25.2-75.8h25.8l25,75.8h0.5
		l22-75.8h33.2L858.7,233.5z"/>
</g>
	<g>
	<path class="st4" d="M988.9,70c41.5,0,62.8,18.8,62.8,55.2c0,36.2-21.2,55-62.8,55h-36.2V236h-37V70H988.9z M987.2,147.3
		c18.5,0,27-7,27-22c0-15.2-8.5-22.2-27-22.2h-34.5v44.2H987.2z"/>
		<path class="st4" d="M1154.4,236l-1-15.8h-0.5c-7.8,11.5-20.8,18.2-38.2,18.2c-33.2,0-57.5-25.8-57.5-61.2s24.2-61.2,57.5-61.2
		c17.2,0,30.5,6.5,38,17.8h0.5l1.2-15.2h27.2V236H1154.4z M1149.9,177.3c0-19-12.2-32.2-29.8-32.2c-17.8,0-30,13.2-30,32.2
		s12.2,32.2,30,32.2C1137.7,209.5,1149.9,196.3,1149.9,177.3z"/>
		<path class="st4" d="M1294.9,236l-1-15.8h-0.5c-7.8,11.5-20.8,18.2-38.2,18.2c-33.2,0-57.5-25.8-57.5-61.2s24.2-61.2,57.5-61.2
		c14.5,0,25.8,4.5,33.8,12.5h0.5V61h32.8v175H1294.9z M1290.4,177.3c0-19-12.2-32.2-29.8-32.2c-17.8,0-30,13.2-30,32.2
		s12.2,32.2,30,32.2C1278.2,209.5,1290.4,196.3,1290.4,177.3z"/>
</g>
</svg>