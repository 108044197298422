import type { MenuLink } from '$lib/domain/menu-link.interface';

export const menuTree: MenuLink[] = [
		{
				text: 'Services',
				url: '',
				submenues: [
						{
								text: 'Startup studio',
								url: '/software-development-for-startups/',
						},
						{
								text: 'Product design and development',
								url: '/product-development-services/',
						},
						{
								text: 'Platform Development',
								url: '/platform-development/',
						},
						{
								text: 'Technology Modernization',
								url: '/technology-modernization/',
						},
						{
								text: 'Team extension',
								url: '/team-extension-services/',
								submenu: [
										{
												text: 'Staff Augmentation',
												url: '/it-staff-augmentation-services/'
										},
										{
												text: 'Dedicated development team',
												url: '/dedicated-development-team/'
										},
								]
						},
						{
								text: 'IT support',
								url: '/support-and-maintenance/',
						},
				],
		},
		{
				text: 'Expertises',
				url: '',
				submenues: [
						{
								text: 'Web development',
								url: '/web-development/',
						},
						{
								text: 'Mobile app development',
								url: '/mobile-development/',
						},
						{
								text: 'UI/UX design',
								url: '/ui-ux-design-services/',
						},
						{
								text: 'QA services',
								url: '/quality-assurance/',
						},
						{
								text: 'Enterprise Application Development',
								url: '/enterprise-application-development-services/',
								submenu: [
										{
												text:'CRM System',
												url:'/custom-crm-development-services/'
										},
										{
												text:'ERP System',
												url:'/erp-development-services/'
										},
										{
												text:'HR System',
												url:'/human-resource-management-software/'
										},
								]
						},
						{
								text: 'Technology',
								url: '/technology/',
								submenu: [
										{
												text: '.NET',
												url: '/net-software-development-company/'
										},
										{
												text: 'Java',
												url: '/java-development-services/'
										},
										{
												text: 'React',
												url: '/reactjs-development-services/'
										},
										{
												text: 'Angular',
												url: '/angular-development-services/'
										},
										{
												text: 'Vue.js',
												url: '/vue-js-development-services/'
										},
										{
												text: 'Flutter',
												url: '/flutter-app-development-services/'
										},
										{
												text: 'Xamarin',
												url: '/xamarin-app-development-services/'
										},
								]
						},
				],
		},
		{
				text: 'Industries',
				url: '',
				submenues: [
						{
								text: 'Healthcare',
								url: '/healthcare-software-development/',
								submenu: [
										{
												text: 'Telemedicine',
												url: '/telemedicine-software-development/',
										},
										{
												text: 'Laboratory Information Management',
												url: '/laboratory-information-management-systems/',
										},
										{
												text: 'EHR and EMR',
												url: '/electronic-medical-records-software-development/',
										},
										{
												text: 'CRM for Healthcare',
												url: '/healthcare-crm-software/',
										}
								]
						},
						{
								text: 'Wellness',
								url: '/fitness-wellness-development/',
						},
						{
								text: 'Logistics',
								url: '/logistics-software-development/',
								submenu: [
										{
												text: 'Fleet Management',
												url: '/fleet-management-software-development-company/',
										},
										{
												text: 'Freight Forwarding',
												url: '/freight-forwarding-software-solutions/',
										},
										{
												text: 'Mobility',
												url: '/mobility-software-development/',
										},
										{
												text: 'Warehouse Management',
												url: '/warehouse-management-system-wms/',
										},
										{
												text: 'CRM for Logistics',
												url: '/crm-software-for-logistics/',
										},
								]
						},
						{
								text: 'GreenTech',
								url: '/greentech-software-development/',
						},
						{
								text: 'Construction',
								url: '/construction-software-development/',
						},
						{
								text: 'Finance',
								url: '/finance-software-development/',
						},
						{
								text: 'Education',
								url: '/education-software-development-services/',
						},
						{
								text: 'Energy & Utilities',
								url: '/energy-and-utilities-software-development-services/',
						},
						{
								text: 'Media & Entertainment',
								url: '/media-and-entertainment-software-development/',
						},
				],
		},
		{
				text: 'Our works',
				url: '/our-works/',
		},
		{
				text: 'About us',
				url: '',
				submenues: [
						{
								text: 'Who we are',
								url: '/about-us/',
						},
						{
								text: 'How we work',
								url: '/approach/',
						},
						{
								text: 'Our clients',
								url: '/our-clients/',
						},
						{
								text: 'Our team',
								url: '/our-team/',
						},
				],
		},
		{
				text: 'Career',
				url: '/career/'
		},
		{
				text: 'Blog',
				url: '/blog/'
		}
];